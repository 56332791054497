const BannerText = ({ screenWidth }) => {
  return (
    <div
      style={{
        paddingTop: 50,
        paddingBottom: 50,
        background: "#191919",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          marginTop: 20,
          marginBottom: 20,
          color: "white",
          fontFamily: "Poppins, sans-serif",
          fontWeight: 500,
          fontSize: screenWidth <= 830 ? 24 : 42,
          textAlign: "center",
          width: "50%",
        }}
      >
        EN NUESTRA INCUBADORA DE IDEAS NACERÁN TUS ESTRATEGIAS
      </h1>
    </div>
  );
};

export default BannerText;
