import Logo from "../../assets/logo.png";
import TramaCircular from "../../assets/trama_circular.jpg";

const Banner = ({ screenWidth }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${TramaCircular})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        height:
          screenWidth > 1000 ? "100vh" : screenWidth > 600 ? "50vh" : "25vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {/* {screenWidth <= 830 ? (
        <img src={Logo} width={300} height={100} />
      ) : (
        <img src={Logo} width="40%" height="50%" />
      )} */}
    </div>
  );
};

export default Banner;
