export const sendMails = async (data) => {
  try {
    const response = await fetch(
      "https://guao-backend-app-zosgp.ondigitalocean.app/api/v1/mail",
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    return Promise.reject(err);
  }
};
