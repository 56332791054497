const Body = ({ screenWidth }) => {
  return (
    <div
      style={{
        paddingTop: 30,
        paddingBottom: 30,
        background: "#eae1df",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          marginTop: 100,
          marginBottom: 20,
          color: "#1d1d1d",
          fontFamily: "Poppins, sans-serif",
          fontWeight: 800,
          fontSize: screenWidth ? 42 : 62,
          textAlign: "start",
          width: "50%",
        }}
      >
        SI TU EMPRESA FUERA NUESTRA....
      </h1>
      <h1
        style={{
          marginTop: 20,
          marginBottom: 20,
          color: "#1d1d1d",
          fontFamily: "Poppins, sans-serif",
          fontWeight: 800,
          fontSize: screenWidth ? 42 : 62,
          textAlign: "start",
          width: "50%",
        }}
      >
        ¿QUÉ HARÍAMOS?
      </h1>
      <p
        style={{
          marginTop: 20,
          marginBottom: 100,
          color: "#1d1d1d",
          fontFamily: "Poppins, sans-serif",
          fontWeight: 300,
          fontSize: screenWidth ? 22 : 36,
          textAlign: "start",
          width: "50%",
        }}
      >
        Todo nuestro equipo de especialistas para cada necesidad, se basa en
        esta pregunta para generar acciones estratégicas y soluciones
        personalizadas para tu negocio.
      </p>
    </div>
  );
};

export default Body;
