import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { sendMails } from "../../api/api";

const Footer = ({ screenWidth }) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    name: "",
    surname: "",
    phone: "",
    company: "",
    email: "",
    interest: "",
  });
  
  const handleSubmit = async () => {
    const response = await sendMails(data);

    console.log(response);
    handleClose()
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div
      style={{
        background: "#1d1d1d",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          marginTop: 100,
          marginBottom: 0,
          color: "white",
          fontFamily: "Poppins, sans-serif",
          fontWeight: 800,
          fontSize: screenWidth ? 62 : 96,
          textAlign: "center",
          width: "50%",
        }}
      >
        CRECE
      </h1>
      <p
        style={{
          color: "white",
          fontFamily: "Poppins, sans-serif",
          fontWeight: 300,
          fontSize: screenWidth ? 22 : 36,
          textAlign: "center",
          width: "50%",
        }}
      >
        CON NOSOTROS
      </p>
      <div className="d-grid gap-2 d-md-block">
        <button
          type="button"
          className="btn btn-light btn-lg"
          onClick={handleShow}
          style={{
            fontWeight: 500,
            padding: 30,
            fontSize: screenWidth ? 22 : 36,
            marginTop: 100,
          }}
        >
          ¡COMIENZA YA!
        </button>
      </div>
      <p
        style={{
          marginTop: 150,
          color: "white",
          fontFamily: "Poppins, sans-serif",
          fontWeight: 300,
          fontSize: screenWidth ? 18 : 24,
          textAlign: "center",
          width: "50%",
        }}
      >
        CENTRO EMPRESARIAL PLAZA MADRID, PISO 3 OFICINA 3-5
      </p>
      <p
        style={{
          marginBottom: 50,
          color: "white",
          fontFamily: "Poppins, sans-serif",
          fontWeight: 300,
          fontSize: screenWidth ? 18 : 24,
          textAlign: "center",
          width: "50%",
        }}
      >
        BARQUISIMETO - VENEZUELA
      </p>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontFamily: "Poppins, sans-serif" }}>
            Ingresa tu información
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label style={{ fontFamily: "Poppins, sans-serif" }}>
                Nombre
              </Form.Label>
              <Form.Control
                style={{ fontFamily: "Poppins, sans-serif" }}
                type="text"
                placeholder=""
                onChange={(e) => setData((prev) => ({
                  ...prev,
                  name: e?.target.value
                }))}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicLastname">
              <Form.Label style={{ fontFamily: "Poppins, sans-serif" }}>
                Apellido
              </Form.Label>
              <Form.Control
                style={{ fontFamily: "Poppins, sans-serif" }}
                type="text"
                placeholder=""
                onChange={(e) => setData((prev) => ({
                  ...prev,
                  surname: e?.target.value
                }))}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCompany">
              <Form.Label style={{ fontFamily: "Poppins, sans-serif" }}>
                Empresa
              </Form.Label>
              <Form.Control
                style={{ fontFamily: "Poppins, sans-serif" }}
                type="text"
                placeholder=""
                onChange={(e) => setData((prev) => ({
                  ...prev,
                  company: e?.target.value
                }))}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontFamily: "Poppins, sans-serif" }}>
                Correo electrónico
              </Form.Label>
              <Form.Control
                style={{ fontFamily: "Poppins, sans-serif" }}
                type="email"
                placeholder=""
                onChange={(e) => setData((prev) => ({
                  ...prev,
                  email: e?.target.value
                }))}
              />
              <Form.Text
                style={{ fontFamily: "Poppins, sans-serif" }}
                className="text-muted"
              >
                Nunca compartiremos su correo electrónico con nadie más.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Label style={{ fontFamily: "Poppins, sans-serif" }}>
                Teléfono
              </Form.Label>
              <Form.Control
                style={{ fontFamily: "Poppins, sans-serif" }}
                type="text"
                placeholder=""
                onChange={(e) => setData((prev) => ({
                  ...prev,
                  phone: e?.target.value
                }))}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Label style={{ fontFamily: "Poppins, sans-serif" }}>
                ¿Cómo quieres hacer crecer tu empresa?
              </Form.Label>
              <Form.Check
                defaultChecked={true}
                style={{ fontFamily: "Poppins, sans-serif" }}
                name="group1"
                type="radio"
                label="Posicionamiento"
                className="ml-0"
                onChange={(e) => setData((prev) => ({
                  ...prev,
                  interest: e?.target.checked && "Posicionamiento"
                }))}
              />
              <Form.Check
                style={{ fontFamily: "Poppins, sans-serif" }}
                name="group1"
                type="radio"
                label="Alcance"
                onChange={(e) => setData((prev) => ({
                  ...prev,
                  interest: e?.target.checked && "Alcance"
                }))}
              />
              <Form.Check
                style={{ fontFamily: "Poppins, sans-serif" }}
                name="group1"
                type="radio"
                label="Ventas"
                onChange={(e) => setData((prev) => ({
                  ...prev,
                  interest: e?.target.checked && "Ventas"
                }))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="lg"
            variant="primary"
            style={{ background: "#4d266f", fontFamily: "Poppins, sans-serif" }}
            onClick={handleSubmit}
          >
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Footer;
