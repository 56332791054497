import { useWindowSize } from "usehooks-ts";
import Banner from "./components/banner/Banner";
import BannerText from "./components/banner/BannerText";
import Body from "./components/body/Body";
import Footer from "./components/footer/Footer";

function App() {
  const { width } = useWindowSize();

  return (
    <div>
      <Banner screenWidth={width} />
      <BannerText screenWidth={width} />
      <Body screenWidth={width} />
      <Footer screenWidth={width} />
    </div>
  );
}

export default App;
